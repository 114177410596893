.viewer_root {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  gap: 1em;
}
.viewer_root .image_Viewer {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.viewer_root .viewer_content {
  height: 100%;
  font-family: SF UI Display;
  width: 25%;
}
.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.Main_Image {
  width: 90%;
  height: 85vh;
}

@media only screen and (max-width: 1200px) {
  .viewer_root {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .viewer_root .image_Viewer {
    width: 100%;
  }
  .viewer_root .viewer_content {
    width: 100%;
    justify-content: center;
  }
  .Main_Image {
    width: 100%;
    height: auto;
  }
  .Divider_Class {
    display: none;
  }
}
