:root {
  --margin: 6em;
}
.landing_root {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./Assets/LandingPageBG.png");
  background-position: center;
  overflow: hidden;
  font-weight: bold;
}
.landing_nav img {
  position: fixed;
  margin-top: 1em;
}
.landing_nav img:first-child {
  left: 0;
  margin-left: var(--margin);
}
.landing_nav img:last-child {
  right: 0;
  margin-right: var(--margin);
}
.landing_Bottom img {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 1em;
}
.landing_center {
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-family: SF UI Display;
  margin-left: var(--margin);
  gap: 1em;
}
.landing_center .custom_BTN {
  padding-block: 0.8em;
  padding-inline: 1em;
  font-weight: bold;
  width: 257.33px;
}
.form_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em;
  position: relative;
}
.form_Holder {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1em;
}
.form_Holder input {
  padding: 1em;
  border: 1px solid #757575;
  outline: none;
}
.form_Holder label {
  color: black;
  font-family: SF UI Display;
}
.form_Holder input:focus {
  border-color: black;
}

.form_root #start_btn:disabled {
  background-color: white;
  color: black;
  cursor: default;
}
.form_root .close_BTN {
  position: absolute;
  right: 0;
  top: 0;
}
.landing_center .firstheading {
  font-size: 50px;
  width: fit-Content;
}

.landing_center .heading {
  font-size: 64px;
  width: fit-Content;
  color: #e2583e;
}
@media only screen and (max-width: 1200px) {
  .form_root {
    padding: 1em;
  }
  .landing_nav img:first-child {
    margin-left: 1em;
  }
  .landing_nav img:last-child {
    margin-right: 1em;
  }
  .landing_center {
    margin-left: 1em;
    align-items: flex-start;
    gap: 0.5em;
  }
  .landing_center .firstheading {
    font-size: 35px !important;
  }
  .landing_center .heading {
    font-size: 40px !important;
    width: 90%;
  }
  .landing_center .custom_BTN {
    width: 187px;
    font-size: 14px;
  }
}
