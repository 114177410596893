.loading_root {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: SF UI Display;
  opacity: 1;
  background-color: white;
  font-weight: bold;
}
.center_container {
  height: 100%;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;
}
.loading_Bar {
  position: relative;
  width: 30%;
  height: 1em;
  background: #dfdfe7;
}
.loadingBottom_Logo {
  position: fixed;
  bottom: 0;
  margin: 1em;
}
.right_bottom {
  right: 0;
}
.left_bottom {
  left: 0;
}
.loadingBar_filler {
  height: inherit;
  content: "";
  position: absolute;
  transition: 0.1s;
  background: linear-gradient(90deg, #f5fbff, #53b0ae);
}
@media only screen and (max-width: 1200px) {
  .right_bottom {
    width: 12em;
  }
  .left_bottom {
    width: 6em;
  }
  .loading_Bar {
    width: 80%;
  }
}
