.Avatar_container {
  height: 100vh;
  width: inherit;
  background-image: url(../LandingPage/Assets/LandingPageBG.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.Avatar_Box {
  height: auto;
  width: 600px;
  background-color: white;
  padding-block: 0.5em;
  padding-inline: 2em;
}
.customiser header {
  font-weight: bold;
  font-size: 2em;
  margin-top: 1em;
}

#model_Render {
  width: 423px;
  background-color: black;
  height: 371.48px;
  display: flex;
  justify-content: center;
}
.customiser {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  gap: 1.5em;
}
.Render_Container {
  display: flex;
  gap: 1em;
}
.color-picker {
  justify-content: center;
  width: 100% !important;
  gap: 0.8em !important;
  margin: 0 !important;
}

.color-picker span div {
  border-radius: 30% !important;
  margin: 0 !important;
}
.property_Holder {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}
.property_Holder button,
.change_btn {
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.property_Holder button img {
  width: 90%;
  object-fit: cover;
  border-radius: 100%;
}
.customiser .custom_BTN {
  padding-inline: 1em;
  padding-block: 0.6em;
  width: 50%;
}
@media only screen and (max-width: 1200px) {
  .Avatar_container {
    background: none;
  }
  .Avatar_Box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: inherit;
    padding: 0;
    width: 100%;
  }
  .property_Holder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .Render_Container {
    width: 100%;
    justify-content: center;
    gap: 0.3em;
  }
  #model_Render {
    width: 250px;
    height: 300px;
  }
  .customiser {
    margin-top: 1em;
  }
  .customiser header {
    font-size: 1.5em;
  }
}
