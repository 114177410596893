.tutorial_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1em;
  font-family: SF UI Display;
  color: white;
}
.tutorial_container header {
  font-weight: bold;
  font-size: 2em;
}
.img_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}
.block_container {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  gap: 1em;
}
.block_container h3 {
  margin: 0;
}
.tutorial_container .custom-btn {
  background-color: #eb5a02;
  border: none !important;
  width: 50%;
  color: white;
  align-self: center;
}
@media only screen and (max-width: 1200px) {
  .tutorial_container header {
    font-size: 1.5em;
  }
}
