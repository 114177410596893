.Side_Content_root {
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  margin-top: 2em;
  gap: 2em;
  height: 100%;
  justify-content: start;
  font-weight: bold;
}
.text_Content {
  overflow-y: auto;
  text-align: left;
  line-height: 1.5rem;
}

@media only screen and (max-width: 1200px) {
  .text_Content {
    max-height: unset !important;
  }
  .Side_Content_root {
    margin-right: 0em;
    gap: 1em;
  }
  .Content_Holder {
    padding: 2em;
  }
}
