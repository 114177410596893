.experienceUI_root {
  width: 100%;
  position: fixed;
  font-family: SF UI Display;
}
.experienceUI_nav {
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  backdrop-filter: blur(5px);
}
.experienceUI_nav text {
  color: white;
  cursor: pointer;
  padding: 1em;
}
.experienceUI_footer {
  position: fixed;
  bottom: 0;
  color: black;
  width: inherit;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}
.Explore_Container {
  margin-inline: 2.8rem;
  padding-block: 1em;
  width: inherit;
  white-space: nowrap;
  gap: 1em;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.Explore_Container_Card {
  width: 162.09px;
  height: 174.15px;
  display: inline-block;
  margin-inline-end: 1em;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.Explore_Container_Card:hover .hover_Container {
  transform: translateY(0);
  opacity: 1;
}
.hover_Container {
  transform: translateY(-1em);
  display: flex;
  color: white;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: inherit;
  height: inherit;
  border-radius: 0.5em;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 1.5em;
  word-spacing: 0.1em;
  opacity: 0;
  transition: 0.5s;
}

.Explore_Root {
  transition: opacity 0.26s ease-in-out, max-height 0.26s ease-in-out;
}
.increaseHeight {
  transform: translateY(0);
  opacity: 1;
  max-height: 1000px;
}
.decreaseHeight {
  transform: translateY(-500px);
  opacity: 0;
  max-height: 0;
}
.nav_Holder {
  display: flex;
  margin-left: 1em;
  font-weight: bold;
}
.nav_PC {
  display: flex;
  align-items: center;
}
.nav_Mobile {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .experienceUI_footer text {
    display: none;
  }
  .experienceUI_footer {
    justify-content: end;
  }
  .hover_Container {
    display: flex;
  }
  .nav_Holder {
    justify-content: center;
    margin-left: 0em;
    text-align: center;
  }
  .nav_Holder text {
    width: 100%;
  }
  .nav_PC {
    display: none;
  }
  .nav_Mobile {
    display: flex;
    flex-direction: column;
    color: white;
    overflow-y: auto;
    font-weight: bold;
    height: 55vh;
  }
  .decreaseHeight {
    transform: translateY(-1000px);
  }
}
