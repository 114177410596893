body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

@font-face {
  font-family: SF UI Display;
  src: url(../Fonts/sf-ui-display-medium-58646be638f96.otf);
  font-weight: bold;
}
@font-face {
  font-family: SF UI Display;
  src: url(../Fonts/sf-ui-display-thin-58646e9b26e8b.otf);
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#studio {
  position: fixed;
  height: 100%;
  width: 100%;
}
.BTN {
  border: 1.5px black solid;
  background-color: white;
  font-family: SF UI Display;
  font-size: 1rem;
  cursor: pointer;
  letter-spacing: 0.2em;
}
.black_btn {
  padding-inline: 1em;
  padding-block: 0.8em;
  background-color: black;
  color: white;
  border: 1.5px black solid;
  font-family: SF UI Display;
  font-size: 1rem;
  cursor: pointer;
  letter-spacing: 0.2em;
  font-weight: bold;
  transition: background-color 0.5s, color 0.5s;
}
